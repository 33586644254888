var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.insurance)?_c('v-row',[_c('v-col',{staticStyle:{"max-width":"35px"}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.nr + 1))])]),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"title",attrs:{"cols":"12","md":"2"}},[_vm._v(_vm._s(_vm.person.name))]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"input-container d-flex align-center"},[_c('zw-autocomplete',{staticClass:"mr-2",attrs:{"name":_vm.nr + '_insurance',"label":"Versicherung auswählen","items":_vm.insurancesFiltered,"item-text":"name","item-value":"id","validation":"required"},model:{value:(_vm.insurance.insurance),callback:function ($$v) {_vm.$set(_vm.insurance, "insurance", $$v)},expression:"insurance.insurance"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information ")])]}}],null,false,1455386558)},[_c('span',[_vm._v("information")])])],1)]),(_vm.insurance.insurance)?[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('zw-checkbox',{attrs:{"name":_vm.nr + '_corona',"label":"Corona Reiseschutz"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Corona Reiseschutz "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information ")])]}}],null,false,1455386558)},[_c('span',[_vm._v("information")])])]},proxy:true}],null,false,2416114260),model:{value:(_vm.insurance.corona),callback:function ($$v) {_vm.$set(_vm.insurance, "corona", $$v)},expression:"insurance.corona"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('zw-checkbox',{attrs:{"name":_vm.nr + '_sb',"label":"SB Auschluss"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" SB Auschluss "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information ")])]}}],null,false,1455386558)},[_c('span',[_vm._v("information")])])]},proxy:true}],null,false,3621400197),model:{value:(_vm.insurance.sb),callback:function ($$v) {_vm.$set(_vm.insurance, "sb", $$v)},expression:"insurance.sb"}})],1)]:_c('v-col',{attrs:{"cols":"12","md":"5"}}),_c('v-col',{staticClass:"text-right title",attrs:{"cols":"12","md":"2"}},[_vm._v(" Preis "+_vm._s(_vm.insurancePrice.toFixed(2))+" € ")])],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }