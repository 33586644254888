<template>
    <v-row>
        <v-col style="max-width: 35px">
            <div class="title">{{nr + 1}}</div>
        </v-col>
        <v-col>
            <v-row v-if="errors[nr+'_cure'] && errors[nr+'_cure'].length">
                <v-col>
                    <v-alert dense
                             type="warning"
                    >Bei Kurpaketen müssen mindestens 5 Tage (Werktage) gebucht werden. Am Wochenende erfolgt keine
                        Kurbehandlung.
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="3">
                    <zw-combobox v-model="service.persons"
                                 :name="nr+'_persons'"
                                 label="Person auswählen"
                                 :items="personsList"
                                 :counter="1"
                                 item-text="name"
                                 item-value="id"
                                 multiple
                                 @change="personsChanged(service)"
                    ></zw-combobox>
                </v-col>
                <v-col cols="6" md="3">
                    <zw-autocomplete v-model="service.cure"
                                     :name="nr + '_cure'"
                                     label="Kurpaket"
                                     :items="curePackagesList"
                                     item-text="name"
                                     item-value="sku"
                    ></zw-autocomplete>
                </v-col>
                <v-col cols="6" md="3">
                    <zw-date-picker2 v-model="service.cure_range"
                                     :name="nr + '_cure'"
                                     label="von - bis"
                                     :range="true"
                                     :min="from"
                                     :max="till"
                                     :picker-date.sync="fromPicker"
                                     :validation="{required: service.cure ? true: false, min_working_days: 5}"
                                     :allowed-dates="allowedDates"
                    ></zw-date-picker2>
                </v-col>
                <v-col cols="6" md="2"  class="text-right title">
                    Preis p.P. {{cureServicePrice.toFixed(2)}} €
                </v-col>
                <v-col cols="12" md="1" class="text-right title">
                    <div>
                        <v-btn @click="deleteService(nr)" v-if="nr>0" color="error" icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="3">
                </v-col>
                <v-col cols="6" md="3">
                    <zw-autocomplete v-model="service.wellness"
                                     :name="nr + '_wellness'"
                                     label="Wellnesspaket"
                                     :items="wellnessPackagesList"
                                     item-text="name"
                                     item-value="sku"
                    ></zw-autocomplete>
                </v-col>
                <v-col cols="6" md="3">
                    <zw-date-picker2 v-model="service.wellness_range"
                                     :name="nr + '_wellness'"
                                     label="von - bis"
                                     :range="true"
                                     :min="from"
                                     :max="till"
                                     :picker-date.sync="fromPicker"
                                     :validation="{required: service.wellness ? true: false}"
                    ></zw-date-picker2>
                </v-col>
                <v-col cols="6" md="2" class="text-right title">
                    Preis p.P. {{wellnessServicePrice.toFixed(2)}} €
                </v-col>

                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import ZwCombobox from "../../components/ZwCombobox";
    import ZwAutocomplete from "../../components/ZwAutocomplete";
    import ZwDatePicker2 from "../../components/ZwDatePicker2";
    import moment from 'moment'

    export default {
        name: 'ServiceLine',
        components: {ZwDatePicker2, ZwAutocomplete, ZwCombobox},
        props: {
            service: null,
            nr: null,
            from: null,
            till: null,
            persons: [],
            curePackages: [],
            wellnessPackages: [],
            deleteService: null,
            errors: null,
            prices: {},
        },
        data: () => ({
            fromPicker: null
        }),
        mounted() {
            this.fromPicker = this.from
        },
        methods: {
            personsChanged(row) {
                this.$emit('person-changed', row.persons);
            },
            allowedDates(val) {
                return ![6,7].includes(moment(val).isoWeekday());
            },
        },
        computed: {
            personsList() {
                let list = []
                this.persons.forEach(person => {
                    list.push({
                        name: person.name,
                        id: person.index,
                    })
                })
                return list
            },
            curePackagesList() {
                let list = []

                this.curePackages.forEach(p => {
                    list.push({
                        name: p.name + ' - ' + p.price_vk + '€',
                        sku: p.sku,
                    })
                })

                return list
            },
            wellnessPackagesList() {
                let list = []

                this.wellnessPackages.forEach(w => {
                    list.push({
                        name: w.name + ' - ' + parseFloat(w.price_vk).toFixed(2) + '€',
                        sku: w.sku,
                    })
                })

                return list
            },
            cureServicePrice() {
                let price = 0
                const firstPersonId = this.service.persons[0] ? this.service.persons[0].index : null

                if (firstPersonId && this.prices[firstPersonId].cures) {
                    const service = this.prices[firstPersonId].cures.find(cure => {
                        return cure.index == this.nr
                    }) || null

                    if (service) {
                        price = service.price
                    }
                }

                return price || 0
            },
            wellnessServicePrice() {
                let price = 0
                const firstPersonId = this.service.persons[0] ? this.service.persons[0].index : null

                if (firstPersonId && this.prices[firstPersonId].wellnesses) {
                    const service = this.prices[firstPersonId].wellnesses.find(wellness => {
                        return wellness.index == this.nr
                    }) || null

                    if (service) {
                        price = service.price
                    }
                }

                return price || 0
            },
        }
    }
</script>