<template>
    <v-row>
        <v-col style="max-width: 35px">
            <div class="title">{{nr + 1}}</div>
        </v-col>
        <v-col>
            <v-row>
                <template v-for="(error,index) in errors">
                    <v-col cols="12" :key="index" v-if="error.index == nr">
                        <v-alert
                                dense
                                outlined
                                type="error"
                        >{{error.message}}
                        </v-alert>
                    </v-col>
                </template>
                <v-col cols="6" md="3">
                    <zw-autocomplete v-model="room.sku"
                                     :name="nr + '_sku'"
                                     label="Zimmerart"
                                     :items="rooms"
                                     item-text="name"
                                     item-value="sku"
                                     validation="required"
                                     @change="roomChanged()"
                    ></zw-autocomplete>
                </v-col>
                <v-col cols="6" md="2">
                    <zw-text-field v-model="room.quantity"
                                   :name="nr+'_quantity'"
                                   type="number"
                                   append-outer-icon="mdi-plus"
                                   prepend-icon="mdi-minus"
                                   min="1"
                                   step="1"
                                   @click:append-outer="()=>{room.quantity = parseInt(room.quantity) + 1;roomChanged()}"
                                   @click:prepend="()=>{room.quantity = parseInt(room.quantity) - 1;roomChanged()}"
                                   :validation="{required: true, min_value:1, integer: true}"
                    ></zw-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <zw-combobox v-model="room.persons"
                                 :name="nr+'_persons'"
                                 label="Personen"
                                 :items="personsList"
                                 :counter="1"
                                 item-text="name"
                                 item-value="id"
                                 multiple
                                 validation="required"
                                 @change="personsChanged()"
                    ></zw-combobox>
                </v-col>
                <v-col cols="12" md="3">
                    <zw-autocomplete v-model="room.food"
                                     :name="nr+'_food'"
                                     label="Verpflegung"
                                     :items="foodOptions"
                                     item-text="name"
                                     item-value="sku"
                                     validation="required"
                    ></zw-autocomplete>
                </v-col>
                <v-col cols="12" md="9">
                    <zw-textarea v-model="room.notice"
                                 :name="nr+'_notice'"
                                 label="Spezielle Zimmerwünsche"
                    ></zw-textarea>
                </v-col>
                <v-col cols="12" md="3" class="text-right title">
                    <div>
                        Preis p.P. {{(roomPrice / (room.persons.length ? room.persons.length : 1)).toFixed(2)}} €
                    </div>
                    <div>
                        Total preis {{roomPrice.toFixed(2)}} €
                    </div>
                    <div>
                        <v-btn @click="deleteRoom(nr)" v-if="nr>0" color="error" icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </v-col>

                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import ZwAutocomplete from "../../components/ZwAutocomplete"
    import moment from 'moment'
    import ZwTextField from "../../components/ZwTextField";
    import ZwCombobox from "../../components/ZwCombobox";
    import ZwTextarea from "../../components/ZwTextarea";

    export default {
        name: 'RoomLine',
        components: {ZwTextarea, ZwCombobox, ZwTextField, ZwAutocomplete},
        props: {
            room: null,
            nr: null,
            rooms: [],
            food: [],
            persons: [],
            prices: {},
            errors: [],
            bookingData: null,
            deleteRoom: null,
        },
        methods: {
            personsChanged() {
                // const room = this.rooms.filter(room => {
                //     return this.room.sku == room.sku
                // })[0] || null
                //
                // let maxPersons = 0
                // if (room) {
                //     const roomCapacity = room.customValues.capacity || 1
                //
                //     maxPersons = this.room.quantity * roomCapacity
                // }
                //
                // if (this.room.persons.length > maxPersons) {
                //     this.room.persons.pop()
                // }

                this.$emit('person-changed', this.room.persons);
            },
            roomChanged() {
                this.room.persons = []
                this.personsChanged()

                const room = this.rooms.filter(room => {
                    return this.room.sku == room.sku
                })[0] || null

                if (room) {
                    const beds = parseInt(room.customValues.capacity || 1) * this.room.quantity
                    const child_beds = parseInt(room.customValues.capacity_child || 0) * this.room.quantity

                    let selectedBeds = 0
                    let selectedChildBeds = 0

                    this.personsList.forEach(person => {
                        if (person.age > 12 && beds > selectedBeds) {
                            this.room.persons.push(person)
                            selectedBeds += 1
                        }
                        if (person.age <= 12 && child_beds > selectedChildBeds) {
                            this.room.persons.push(person)
                            selectedChildBeds += 1
                        }
                    })
                }

                this.personsChanged()
            }
        },
        computed: {
            roomPrice() {
                let price = 0
                this.room.persons.forEach(person => {

                    if (this.prices[person.index].room_price) {

                        price += parseFloat(this.prices[person.index].room_price) + parseFloat(this.prices[person.index].food_price)
                    }
                })

                return price
            },
            personsList() {
                let list = []
                this.persons.forEach(person => {
                    const selectedInRow = this.room.persons.filter(p => {
                        return p.index == person.index
                    })[0] || null

                    if (!person.disabled || selectedInRow) {
                        list.push({
                            name: person.name,
                            index: person.index,
                            age: person.age,
                        })
                    }
                })
                return list
            },
            foodOptions() {
                let list = []
                const requiredFood = JSON.parse(this.bookingData.hotel_data.customValues.required_food) || []

                let optionalPeriodStart = this.bookingData.hotel_data.customValues.optional_food_start || null
                let optionalPeriodEnd = this.bookingData.hotel_data.customValues.optional_food_till || null

                if (optionalPeriodStart && optionalPeriodEnd) {
                    optionalPeriodStart = moment(optionalPeriodStart + '/' + moment(this.bookingData.to).format('YYYY'), 'DD/MM/YYYY').format('YYYY-MM-DD')
                    optionalPeriodEnd = moment(optionalPeriodEnd + '/' + moment(this.bookingData.to).format('YYYY'), 'DD/MM/YYYY').format('YYYY-MM-DD')
                }

                let optional = false
                if (optionalPeriodStart && optionalPeriodEnd && this.bookingData.to >= optionalPeriodStart && this.bookingData.to <= optionalPeriodEnd) {
                    optional = true
                }

                if (optional) {
                    list.push({
                        name: 'ohne Verpflegung',
                        sku: '-',
                    })
                }

                this.food.forEach(food => {
                    if (optional || requiredFood.includes(food.id)) {
                        list.push(food)
                    }
                })

                return list
            }
        }
    }
</script>