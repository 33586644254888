<template>
    <v-row>
        <v-col style="max-width: 35px">
            <div class="title">{{nr + 1}}</div>
        </v-col>
        <v-col>
            <v-row>
                <v-col cols="12" md="3" class="title">{{name}}</v-col>

                <v-col cols="12" md="3">
                    <zw-autocomplete v-model="transfer.transfer"
                                     :name="nr + '_transfer'"
                                     label="Transfer auswählen"
                                     :items="transfers"
                                     item-text="name"
                                     item-value="sku"
                                     validation="required"
                    ></zw-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                    <template v-if="transfer.transfer && transfer.transfer!='OWN'">
                        <v-row>
                            <v-col cols="4">
                                <zw-checkbox v-model="transfer.walker"
                                             :name="nr + '_walker'"
                                             label="Rollator"
                                ></zw-checkbox>
                            </v-col>
                            <v-col cols="2">
                                <zw-text-field v-model="transfer.walker_quantity"
                                               v-if="transfer.walker"
                                               :name="nr + '_walker_quantity'"
                                               label="Qty"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="4">
                                <zw-checkbox v-model="transfer.wheelchair"
                                             :name="nr + '_wheelchair'"
                                             label="Klapprollstuhl"
                                ></zw-checkbox>
                            </v-col>
                            <v-col cols="2">
                                <zw-text-field v-model="transfer.wheelchair_quantity"
                                               v-if="transfer.wheelchair"
                                               :name="nr + '_wheelchair_quantity'"
                                               label="Qty"
                                ></zw-text-field>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>
            </v-row>

            <v-row v-if="transfer.transfer && transfer.transfer!='OWN'">
                <v-col cols="12" md="12">
                    <v-row>
                        <v-col cols="12" md="3">
                            <zw-text-field v-model="transfer.address.zip"
                                           name="zip"
                                           label="PLZ"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                            <zw-text-field v-model="transfer.address.city"
                                           name="city"
                                           label="Ort"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                            <zw-text-field v-model="transfer.address.address"
                                           name="address"
                                           label="Straße"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                            <zw-text-field v-model="transfer.address.phone"
                                           name="phone"
                                           label="Telefonnummer"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                            <zw-text-field v-model="transfer.address.email"
                                           name="email"
                                           label="E-mail"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>

                        <v-col cols="12" md="9" class="text-right title">
                            <template v-if="sameTransport">Preis {{transferPrice.toFixed(2)}} €</template>
                            <template v-else>Preis p.P. {{transferPrice.toFixed(2)}} €</template>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import ZwAutocomplete from "../../components/ZwAutocomplete";
    import ZwCheckbox from "../../components/ZwCheckbox";
    import ZwTextField from "../../components/ZwTextField";

    export default {
        name: 'Transfer',
        components: {ZwTextField, ZwCheckbox, ZwAutocomplete},
        props: {
            transfer: null,
            name: null,
            transfers: [],
            persons: [],
            nr: null,
            prices: {},
            sameTransport: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            transferPrice() {
                let price = 0

                if (this.sameTransport) {
                    this.persons.forEach(person => {
                        price += parseFloat(this.prices[person.index].transfer_price)
                    })
                } else {
                    price = parseFloat(this.prices[this.transfer.traveler].transfer_price)

                }

                return price || 0
            },
        },
    }
</script>