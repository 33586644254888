<template>
    <v-row v-if="insurance">
        <v-col style="max-width: 35px">
            <div class="title">{{nr + 1}}</div>
        </v-col>
        <v-col>
            <v-row>
                <v-col cols="12" md="2" class="title">{{person.name}}</v-col>

                <v-col cols="12" md="3">
                    <div class="input-container d-flex align-center">
                        <zw-autocomplete v-model="insurance.insurance"
                                         :name="nr + '_insurance'"
                                         label="Versicherung auswählen"
                                         :items="insurancesFiltered"
                                         item-text="name"
                                         item-value="id"
                                         validation="required"
                                         class="mr-2"
                        ></zw-autocomplete>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs"
                                        v-on="on"
                                >mdi-information
                                </v-icon>
                            </template>
                            <span>information</span>
                        </v-tooltip>
                    </div>

                </v-col>

                <template v-if="insurance.insurance">
                    <v-col cols="12" md="3">
                        <zw-checkbox v-model="insurance.corona"
                                     :name="nr + '_corona'"
                                     label="Corona Reiseschutz"
                        >
                            <template v-slot:label>
                                Corona Reiseschutz
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs"
                                                v-on="on"
                                        >mdi-information
                                        </v-icon>
                                    </template>
                                    <span>information</span>
                                </v-tooltip>
                            </template>
                        </zw-checkbox>

                    </v-col>

                    <v-col cols="12" md="2">
                        <zw-checkbox v-model="insurance.sb"
                                     :name="nr + '_sb'"
                                     label="SB Auschluss"
                        >
                            <template v-slot:label>
                                SB Auschluss
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs"
                                                v-on="on"
                                        >mdi-information
                                        </v-icon>
                                    </template>
                                    <span>information</span>
                                </v-tooltip>
                            </template>
                        </zw-checkbox>
                    </v-col>
                </template>
                <v-col v-else cols="12" md="5">
                </v-col>

                <v-col cols="12" md="2" class="text-right title">
                    Preis {{insurancePrice.toFixed(2)}} €
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import ZwAutocomplete from "../../components/ZwAutocomplete";
    import ZwCheckbox from "../../components/ZwCheckbox";

    export default {
        name: 'Insurance',
        components: {ZwCheckbox, ZwAutocomplete},
        props: {
            insurance: null,
            insurances: [],
            person: null,
            nr: null,
            crn: null,
            osb: null,
            prices: {},
            sameInsurance: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            insurancePrice() {
                let price = 0

                if (this.sameInsurance) {
                    price = parseFloat(this.prices[this.person.index].insurance_price) * Object.keys(this.prices).length
                } else {
                    price = parseFloat(this.prices[this.person.index].insurance_price)
                }

                return price
            },
            insurancesFiltered() {
                let newList = []
                this.insurances.forEach(insurance => {
                    if(this.person.age_now >= 65) {
                        if(!insurance.name.includes('64')) {
                            newList.push(insurance)
                        }
                    } else {
                        if(!insurance.name.includes('65')) {
                            newList.push(insurance)
                        }
                    }
                })
                return newList
            }
        }
    }
</script>