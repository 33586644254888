<template>
    <v-dialog
            ref="dialog"
            v-model="modal"
            width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <validation-provider
                    v-slot="{ errors }"
                    :name="$attrs.name"
                    :rules="$props.validation"
                    :mode="$attrs.mde"
            >
                <v-text-field
                        v-model="inputVal"
                        outlined
                        dense
                        hide-details
                        :label="$attrs.label"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        @click="modal=true"
                        :error-messages="errors"
                ></v-text-field>
            </validation-provider>
        </template>
        <v-date-picker
                v-model="inputVal"
                :active-picker.sync="activePickerInternal"
                locale="de"
                @input="save"
                :first-day-of-week="1"
                v-bind="$attrs"
                v-on="$listeners"
        >
        </v-date-picker>
    </v-dialog>
</template>

<script>
    export default {
        name: 'ZwDatePicker2',
        props: {
            activePicker: {
                default: 'DATE'
            },
            validation: {
                type: [String, Object],
            },
            value: null
        },
        data: () => ({
            activePickerInternal: 'DATE',
            modal: false,
        }),
        methods: {
            save(date) {
                if (!this.$attrs.range || date.length == 2) {
                    this.$refs.dialog.save(date)
                }
                this.$emit('input', date);
            },
        },
        watch: {
            modal(val) {
                val && setTimeout(() => (this.activePickerInternal = this.activePicker))
            },
        },
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val)
                }
            }
        },
    }
</script>