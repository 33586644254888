<template>
    <v-container>
        <validation-observer tag="div" ref="observer">
            <v-card class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Zimmer und Verpflegung</div>
                        </v-col>
                    </v-row>

                    <template v-if="form.rooms">
                        <room-line v-for="(room, index) in form.rooms"
                                   :key="index"
                                   :nr="index"
                                   :room="room"
                                   :rooms="getRooms()"
                                   :food="getFood()"
                                   :persons="persons"
                                   :prices="prices"
                                   :errors="errors"
                                   :booking-data="form"
                                   :deleteRoom="deleteRoom"
                                   @person-changed="personChanged()"
                        ></room-line>
                    </template>
                    <v-row>
                        <v-col class="text-right">
                            <v-btn v-if="persons.find(person=>!person.disabled)" color="primary" @click="addRoom">
                                <v-icon>mdi-plus</v-icon>
                                hinzufügen
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Wellness- und Kurpaket</div>
                        </v-col>
                    </v-row>

                    <template v-if="form.services">
                        <service-line v-for="(service, index) in form.services"
                                      :key="index"
                                      :nr="index"
                                      :service="service"
                                      :persons="persons"
                                      :cure-packages="getCurePackages()"
                                      :wellness-packages="getWellnessPackages()"
                                      :from="form.from"
                                      :till="form.to"
                                      :delete-service="deleteService"
                                      :errors="$refs['observer'].errors"
                                      :prices="prices"
                        ></service-line>
                    </template>

                    <v-row>
                        <v-col class="text-right">
                            <v-btn color="primary" @click="addService">
                                <v-icon>mdi-plus</v-icon>
                                hinzufügen
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

            <v-card class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Anreiseart</div>
                        </v-col>
                    </v-row>

                    <v-row v-if="form.hotel_data">
                        <v-col>
                            <v-alert dense
                                     type="info"
                            >
                                Bitte beachten Sie, dass Bustransfer in
                                {{ form.hotel_data.position == 'Ostsee' ? 'Ostseeküste' : 'Gebierge' }}
                                nur bei An- und Abreise am
                                {{ form.hotel_data.position == 'Ostsee' ? 'Samstags' : 'Sonntags' }}
                                möglich ist. An anderen Tagen
                                können Sie entweder unser Limousinentransfer nutzen oder ohne Transfer buchen.
                            </v-alert>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4">
                            <zw-checkbox v-model="form.same_transfer"
                                         label="Für alle Reisenden übernehmen"
                            ></zw-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <zw-checkbox v-model="form.same_address"
                                         label="Adresse wie Reiseanmelder"
                            ></zw-checkbox>
                        </v-col>
                    </v-row>

                    <template v-if="form.same_transfer">
                        <transfer :nr="0"
                                  name="Aller Reisende"
                                  :transfer="form.transfers[0]"
                                  :transfers="transfersList"
                                  :persons="persons"
                                  :prices="prices"
                                  same-transport
                        ></transfer>
                    </template>
                    <template v-else>
                        <transfer v-for="(transfer, index) in form.transfers"
                                  :key="index"
                                  :nr="index"
                                  :name="getPersonName(transfer.traveler)"
                                  :transfer="transfer"
                                  :persons="persons"
                                  :transfers="transfersList"
                                  :prices="prices"
                        ></transfer>
                    </template>
                </v-card-text>
            </v-card>

            <v-card class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Reiseversicherung</div>
                        </v-col>
                    </v-row>

                    <insurance v-for="(insurance, index) in form.insurances"
                               :key="index"
                               :nr="index"
                               :insurance="insurance"
                               :insurances="insurancesList"
                               :prices="prices"
                               :crn="getCrnInsurance()"
                               :osb="getOsbInsurance()"
                               :person="getPerson(insurance.traveler)"
                    ></insurance>
                </v-card-text>
            </v-card>

            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-btn @click="back">Zurück</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="text-right">
                            <v-btn color="primary"
                                   @click="nextStep()"
                            >next step
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </validation-observer>
    </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import ZwCheckbox from "../components/ZwCheckbox";
import Insurance from "./blocks/Insurance";
import Transfer from "./blocks/Transfer";
import RoomLine from "./blocks/RoomLine";
import moment from 'moment'
import price from '../mixins/price'
import ServiceLine from "./blocks/ServiceLine";

export default {
    name: 'Room',
    components: {ServiceLine, RoomLine, Transfer, Insurance, ZwCheckbox},
    mixins: [price],
    data: () => ({
        form: {},
        hash: null,
        persons: [],
        prices: {},
        trns_prices: {},
        trns: {
            bus_person: null,
            bus_full: null,
            limo: null,
        },
        errors: [],
        tmpAddresses: {}
    }),
    mounted() {
        this.$root.$children[0].showLoading()
        const configuration = this.$store.dispatch('Options/fetchConfiguration')

        Promise.all([configuration])
            .then(() => {
                this.form = JSON.parse(localStorage.getItem('form')) || {}

                if (!this.form.rooms.length) {
                    this.addRoom()
                }

                this.form.travelers.forEach((traveler, index) => {

                    this.persons.push({
                        index: index,
                        name: traveler.firstname + ' ' + traveler.lastname,
                        disabled: false,
                        age: moment.duration(moment(this.form.from).diff(moment(traveler.birthday))).asYears(),
                        age_now: moment.duration(moment().diff(moment(traveler.birthday))).asYears()
                    })

                    if (this.form.insurances.filter(i => i.traveler == index).length == 0) {
                        this.form.insurances.push({
                            traveler: index,
                            insurance: 0,
                            corona: false,
                            sb: false,
                        })
                    }

                    if (this.form.transfers.filter(t => t.traveler == index).length == 0) {
                        this.form.transfers.push({
                            traveler: index,
                            transfer: 'OWN',
                            wheelchair: false,
                            wheelchair_quantity: 1,
                            walker_quantity: 1,
                            walker: false,
                            address: {
                                zip: null,
                                city: null,
                                address: null,
                                phone: null,
                                email: null,
                            }
                        })
                    }
                    this.form.transfers.forEach(transfer => {
                        if (typeof transfer.address != 'object') {
                            transfer.address = {
                                zip: null,
                                city: null,
                                address: null,
                                phone: null,
                                email: null,
                            }
                        }
                    })
                })

                if (!this.form.services.length) {
                    this.addService()
                }

                const hotelsList = this.$store.dispatch('Hotels/fetchHotels')
                const roomsList = this.$store.dispatch('Travelbox/fetchRooms', this.form.hotel)
                const foodList = this.$store.dispatch('Travelbox/fetchFood', this.form.hotel)
                const transfersList = this.$store.dispatch('Travelbox/fetchTransfers', this.form.hotel)
                const insurancesList = this.$store.dispatch('Travelbox/fetchInsurances', this.form.insurances)
                const curePackages = this.$store.dispatch('Travelbox/fetchCurePackages', this.form.hotel)
                const wellnessPackages = this.$store.dispatch('Travelbox/fetchWellnessPackages', this.form.hotel)

                Promise.all([hotelsList, roomsList, foodList, transfersList, insurancesList, curePackages, wellnessPackages])
                    .then(() => {
                        this.trns.bus_person = this.getTransfers().find(transfer => transfer.sku.startsWith('TRNS_BUS_PP_')) || null
                        this.trns.bus_full = this.getTransfers().find(transfer => transfer.sku.startsWith('TRNS_BUS_VL_')) || null
                        this.trns.limo = this.getTransfers().find(transfer => transfer.sku.startsWith('TRNS_LIMO_')) || null

                        this.updatePrices()

                        this.$root.$children[0].hideLoading()
                    })
            })
    },
    methods: {
        ...mapGetters('Travelbox', [
            'getRooms',
            'getFood',
            'getTransfers',
            'getInsurances',
            'getCrnInsurance',
            'getOsbInsurance',
            'getWellnessPackages',
            'getCurePackages',
        ]),
        ...mapGetters('Hotels', ['getHotels']),
        ...mapGetters('Options', ['getConfiguration']),
        back() {
            this.$router.push({path: '/'})
        },
        addRoom() {
            this.form.rooms.push({
                sku: null,
                quantity: 1,
                persons: [],
                food: [],
            })
        },
        deleteRoom(delete_index) {
            this.form.rooms = this.form.rooms.filter((value, index) => {
                return delete_index == index ? false : true
            })
        },
        addService() {
            this.form.services.push({
                persons: [],
            })
        },
        deleteService(delete_index) {
            this.form.services = this.form.services.filter((value, index) => {
                return delete_index == index ? false : true
            })
        },
        getPersonName(id) {
            let person = this.persons.filter(person => {
                return person.index == id
            })[0] || null

            if (person) {
                return person.name
            }
        },
        getPerson(id) {
            return this.persons.filter(person => {
                return person.index == id
            })[0] || null
        },
        personChanged() {
            let selected = []
            this.form.rooms.forEach(room => {
                room.persons.forEach(person => {
                    selected.push(person.index)
                })
            })
            this.persons.forEach(person => {
                person.disabled = selected.includes(person.index)
            })
        },
        nextStep() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$root.$children[0].showLoading()
                    localStorage.setItem('form', JSON.stringify(this.form))
                    this.$router.push('/finish')
                }
            })
        },
        updatePrices() {
            this.persons.forEach(person => {
                this.$set(this.prices, person.index, this.calculatePricesForPerson(person.index))
            })
        },
        validateCapacity() {
            this.errors = []
            this.form.rooms.forEach((roomData, index) => {
                const room = this.getRooms().find(room => roomData.sku == room.sku) || null

                let adultSelected = 0
                let childSelected = 0

                const personsCount = roomData.persons.length

                roomData.persons.forEach(personData => {
                    let person = this.persons.find(person => person.index == personData.index)

                    if (person.age > 12) {
                        adultSelected += 1
                    } else {
                        childSelected += 1
                    }
                })

                if (room && personsCount) {
                    const beds = parseInt(room.customValues.capacity || 1) * roomData.quantity
                    const child_beds = parseInt(room.customValues.capacity_child || 0) * roomData.quantity
                    const double = parseInt(room.customValues.double || false)

                    if (!double && beds > adultSelected) {
                        this.errors.push({
                            index: index,
                            message: 'Dieses Zimmer ist für die ausgewählte Belegung nicht verfügbar. Belegung ist ' + beds + ' Erwachsene +' + child_beds + ' Kind(er)',
                        })
                    }
                    if (double && roomData.quantity > adultSelected) {
                        this.errors.push({
                            index: index,
                            message: 'Dieses Zimmer ist für die ausgewählte Belegung nicht verfügbar.',
                        })
                    }
                    if (beds < adultSelected) {
                        this.errors.push({
                            index: index,
                            message: 'Dieses Zimmer ist für die ausgewählte Belegung nicht verfügbar.',
                        })
                    }
                    if (child_beds < childSelected) {
                        this.errors.push({
                            index: index,
                            message: 'Dieses Zimmer ist für die ausgewählte Belegung nicht verfügbar.',
                        })
                    }
                }
            })
        }
    },
    watch: {
        'form': {
            handler: function () {
                this.updatePrices()
                this.validateCapacity()
            },
            deep: true
        },
        'form.same_address': function (val) {
            if (val) {
                this.form.transfers.forEach((transfer, index) => {
                    this.tmpAddresses[index] = JSON.parse(JSON.stringify(transfer.address))

                    transfer.address = {}
                    transfer.address.zip = this.form.customer.zip
                    transfer.address.city = this.form.customer.city
                    transfer.address.address = this.form.customer.address
                    transfer.address.email = this.form.customer.email
                    transfer.address.phone = this.form.customer.phone
                })
            } else {
                this.form.transfers.forEach((transfer, index) => {
                    if (this.tmpAddresses[index]) {
                        transfer.address = this.tmpAddresses[index]
                    }
                })
            }
        }
    },
    computed: {
        insurancesList() {
            let insurances = [{
                id: 0,
                name: 'kein Paket',
                sku: 'OWN',
                price_vk: 0,
            }]

            this.getInsurances().forEach(insurance => {
                insurances.push(insurance)
            })

            return insurances
        },
        transfersList() {
            let fromWeekday = moment(this.form.from).isoWeekday()
            let toWeekday = moment(this.form.to).isoWeekday()

            let transfers = [{id: 0, name: 'Eigenanreise', sku: 'OWN', price_vk: 0}]

            if (fromWeekday == 6 && toWeekday == 6 && this.form.hotel_data.position == 'Ostsee') {
                if (this.trns.bus_full || this.trns.bus_person) {
                    transfers.push({id: -1, name: 'Bustransfer', sku: 'BUS', price_vk: 0})
                }
            }
            if (fromWeekday == 7 && toWeekday == 7 && this.form.hotel_data.position == 'Gebierge') {
                if (this.trns.bus_full || this.trns.bus_person) {
                    transfers.push({id: -1, name: 'Bustransfer', sku: 'BUS', price_vk: 0})
                }
            }

            if (this.trns.limo) {
                transfers.push({id: -2, name: 'Limousinentransfer', sku: 'LIMO', price_vk: 0})
            }

            return transfers
        }
    }
}
</script>

<style>
</style>