<template>
    <validation-provider
            v-slot="{ errors }"
            :name="$attrs.name"
            :rules="$props.validation"
    >
        <v-textarea outlined
                      dense
                      hide-details
                      v-bind="$attrs"
                      v-on="$listeners"
                      :error-messages="errors"
        >
        </v-textarea>
    </validation-provider>
</template>

<script>
    export default {
        name: 'ZwTextarea',
        props: {
            validation: {
                type: [String, Object],
            }
        },
    }
</script>