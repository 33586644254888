<template>
    <validation-provider
            v-slot="{ errors }"
            :name="$attrs.name"
            :rules="$props.validation"
    >
        <v-combobox outlined
                    dense
                    hide-details
                    v-bind="$attrs"
                    v-on="$listeners"
                    :error-messages="errors"
        ></v-combobox>
    </validation-provider>
</template>

<script>
    export default {
        name: 'ZwCombobox',
        props: {
            validation: {
                type: [String, Object],
            }
        },
    }
</script>